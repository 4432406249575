import React from 'react';
import { Helmet } from 'react-helmet';
import DJService from '../components/DJService';
import AcousticBanner from '../components/AcousticBanner';

import Layout from '../components/Layout';

const FAQTemplate = () => (
  <Layout>
    <Helmet>
      <title>Wedding Entertainment Bristol, Bath - Audio Vultures FAQ</title>
      <meta
        name="description"
        content="Frequently asked questions about Audio Vultures, a Bristol based wedding entertainment cover band, covering Bath and nationwide."
      />
    </Helmet>

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Frequently Asked Questions</h1>
          </header>

          <div className="row">
            <div className="6u 12u$(medium)">
              <h5>Set Lengths</h5>
              <p>
                We can play two sets of 45 minutes, two sets of 60 minutes or three sets of 40
                minutes, and generally have a finishing time of midnight. We also offer a late
                finish of 1am as an additional service.
              </p>

              <h5>Unloading / Setting Up Time</h5>
              <p>Our unloading and set up time is generally 90 minutes.</p>

              <h5>Minimum Stage Size / Space</h5>
              <p>
                Our minimum space requirement is a stage of three metres (9.8ft) deep by five meters
                (16.4 ft) deep.
              </p>

              <h5>Band Rider</h5>
              <p>
                We don&apos;t request a rider as such, but it is appreciated if any food and soft
                drinks for four people can be provided.
              </p>
            </div>
            <div className="6u 12u$(medium)">
              <h5>Sound & Lighting Equipment</h5>
              <p>
                Our professional 2500 watt sound system can fill a room of 250 person capacity. We
                also have a powerful LED stage lighting system, including a haze machine. Music
                (from phones, iPods, CD players, etc) can also be played through our sound system
                during the event.
              </p>

              <h5>"Silent Stage" / Lower Volume Setups</h5>
              <p>
                We have multiple equipment setups that include a “silent stage” / lower volume setup
                for sound restricted venues (including an electric drum kit). This is a great
                alternative for venues where a full acoustic drum kit would be problematic.
              </p>

              <h5>Parking & Loading</h5>
              <p>
                We require parking for two vehicles as close to the venue as possible and preferably
                on site. We also require at least temporary parking for unloading our equipment into
                the event venue.
              </p>

              <h5>Health and Safety</h5>
              <p>
                All of our equipment is PAT tested and certified, and we carry public liability
                insurance.
              </p>
            </div>
          </div>

          <hr className="major" />
          <h2>Our Gear & Stage Setup</h2>
          <p>Our regular full stage setup includes:</p>
          <div className="row">
            <div className="4u 12u$(small)">
              <h4>Sound Gear:</h4>
              <ul>
                <li>2500 watt PA system</li>
                <li>2x Yamaha DXR12 MkII active speakers</li>
                <li>1x Yamaha DXS15 MkII Subwoofer</li>
                <li>1x Behringer XR18 digital mixer</li>
                <li>1x Macbook for samples</li>
              </ul>
            </div>
            <div className="4u 12u$(small)">
              <h4>Lighting Gear (DMX programmed):</h4>
              <ul>
                <li>4x Equinox Power LED wall wash light bars</li>
                <li>1x KAM LED Power T-bar Partybar</li>
                <li>1x Cameo Superfly Derby &amp; Strobe</li>
                <li>2x Marq 64par LED cans</li>
                <li>2x Equinox Fusion Spot moving heads</li>
                <li>1x Beamz 1500 watt haze machine </li>
              </ul>
            </div>
            <div className="4u 12u$(medium)">
              <h4>Miscellaneous Gear:</h4>

              <ul>
                <li>Wireless In Ear Monitors</li>
                <li>Professional DJ booth and Pioneer DJ controller</li>
                <li>Alesis Electric drumkit (for sound restricted gigs)</li>
                <li>
                  3 x Power Supply For Floor Effects Units ( stage middle / stage left / stage right
                  )
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      <br /> <br /> <br />
      <AcousticBanner />
      <DJService />
    </div>
  </Layout>
);

export default FAQTemplate;
